import { PluginConfig } from "../modal/PluginConfig";
import { Section } from "../modal/Section";

export const gayatrilodgeTheme: any = {
    name: "minimalist"
}
export const gayatrilodge: Section[] = [
    {
        id: "69e7ed7e-889d-41c0-a1d9-62bd150401e2",
        name: "Header",
        version: "v2",
        style: "CLASSIC",
        path: "index",
        data: {
            type: "Header",
            id: "001",
            title: "Gayatri Lodge",
            subTitle: "Hero Banner Sub Title is",
            description: "Hero Banner Paragraph is here",
            version: "v2",
            style: "CLASSIC",
            menu: [
                {
                    id: "menu-1",
                    label: "Home",
                    url: "/home",
                }, {
                    id: "menu-2",
                    label: "About Us",
                    url: "/about-us",
                }, {
                    id: "menu-4",
                    label: "Gallery",
                    url: "/gallery",
                }, {
                    id: "menu-3",
                    label: "Contact us",
                    url: "/contact-us",
                },
            ],
        },
    },
    {
        id: "69e7ed7e-889d-41c0-a1d9-62bd150401e2",
        name: "Herobanner",
        version: "v1",
        style: "CLASSIC",
        path: "index",
        data: {
            type: "HeroBanner",
            id: "002",
            title: "Gayatri Lodge!",
            subTitle: "Welcome to Gayatri Lodge, Your Tranquil Haven in Kasauli",
            description: "Experience the serenity of Himachal Pradesh at Gayatri Lodge. Nestled in the charming town of Kasauli, our lodge offers an idyllic escape surrounded by nature's beauty.",
            version: "v1",
            style: "CLASSIC",
            bannerData: [
                {
                    title: "Gayatri Lodge!",
                    subTitle: "Welcome to Gayatri Lodge, Your Tranquil Haven in Kasauli",
                    description: "Experience the serenity of Himachal Pradesh at Gayatri Lodge. Nestled in the charming town of Kasauli, our lodge offers an idyllic escape surrounded by nature's beauty.",
                    image:
                    {
                        id: '1',
                        imageSrc: 'https://images.unsplash.com/photo-1597256817041-0c75c0633658?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=849&q=80',
                        imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
                    },
                    modalProps: {
                        isOpen: true,
                        // onClose: () => void,
                        title: 'Plan Your Dream Vacation Package',
                        formFields: [
                            { name: 'name', label: 'Your Good Name', type: 'text', required: true },
                            { name: 'mobile', label: 'Your Phone Number', type: 'tel', required: true },
                            // {
                            //     name: 'mobile', label: 'Preferred Destination', type: 'select', required: true,
                            //     options: [
                            //         { label: 'The Parvati Valley Adventure', value: '3' },
                            //         { label: 'Manali Snow Adventure', value: '5' },
                            //         { label: 'Kasol and Tosh Winter Chill', value: '7' },
                            //         { label: 'Narkanda Snowy Paradise', value: '21' },
                            //     ],
                            // },
                            {
                                name: 'mobile', label: 'Number of Guest', type: 'number', required: true,
                            },
                            {
                                name: 'mobile', label: 'I m Interested In', type: 'checkbox', required: true,
                                options: [
                                    { label: 'All-Inclusive Resorts', value: 'roof_solution' },
                                    { label: 'Stay Only', value: 'adventure_tours' },
                                ],
                            },
                            { name: 'text', label: 'Special Requests ', type: 'textarea', required: false },
                        ]
                    }
                },
            ],
        },
    },
    {
        id: "143d8df3-49c7-4370-9e0e-0c5ef7a46f6b",
        name: "Service",
        version: "v1",
        style: "CLASSIC",
        path: "index",
        data: {
            type: "AboutUs",
            id: "003",
            title: "About us!",
            subTitle: "Hero Banner Sub Title is here",
            description: "At Gayatri Lodge, we believe in creating unforgettable memories for our guests. As a family-owned homestay, we take pride in our personalized approach and attention to detail. With years of experience in hospitality, we have curated a haven where every guest is treated like a part of our extended family. Our warm and friendly staff is dedicated to ensuring your stay is nothing short of exceptional.",
            version: "v1",
            style: "CLASSIC",
            images: [
                {
                    id: '1',
                    imageSrc: 'https://images.unsplash.com/photo-1597256817041-0c75c0633658?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=849&q=80',
                    imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
                },
            ],
        },
    },
    {
        id: "143d8df3-49c7-4370-9e0e-0c5ef7a46f6b",
        name: "Gallery",
        version: "v1",
        style: "CLASSIC",
        path: "index",
        data: {
            type: "Gallery",
            id: "004",
            title: "Our Gallery !",
            subTitle: "Hero Banner Sub Title is here",
            description: "Hero Banner Paragraph is here",
            version: "v1",
            style: "CLASSIC",
            images: [
                {
                    id: '1',
                    imageSrc: 'https://cf.bstatic.com/xdata/images/hotel/max1280x900/462717943.jpg?k=c04575b70bbec6f0aaa5ffeae79e5535d0186ce48a3aabf17a93b8a96d8debed&o=&hp=1',
                    imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
                },
                {
                    id: '2',
                    imageSrc: 'https://cf.bstatic.com/xdata/images/hotel/max1280x900/462717126.jpg?k=0a09a8f39a458aa2160a678ffa42a0f9ac3f9936bd43496122b2a6bf3bd9ac58&o=&hp=1',
                    imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
                },
                {
                    id: '3',
                    imageSrc: 'https://cf.bstatic.com/xdata/images/hotel/max1280x900/444363217.jpg?k=6969ac3214ea38d143abf0f926a92925442a0298f2a2c7de56bcd3d3e930afc8&o=&hp=1',
                    imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
                },
                {
                    id: '4',
                    imageSrc: 'https://cf.bstatic.com/xdata/images/hotel/max1280x900/419192458.jpg?k=d81a58a439acb3db08b438c41ee60dd359f417c8804ec7408b31fcf95d37e13a&o=&hp=1',
                    imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
                },
                {
                    id: '5',
                    imageSrc: 'https://cf.bstatic.com/xdata/images/hotel/max1280x900/462717679.jpg?k=0e58ba5f9e70ce15dcac0a290ef5d995d388e9227086a352e347547caaea7da5&o=&hp=1',
                    imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
                },
                {
                    id: '6',
                    imageSrc: 'https://q-xx.bstatic.com/xdata/images/hotel/max1024x768/444363181.jpg?k=b7d5360a0b7cf06a971d9dfcb4e420936bc734c4b352ec3f4ede9149f91d3482&o=',
                    imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
                }, {
                    id: '7',
                    imageSrc: 'https://q-xx.bstatic.com/xdata/images/hotel/max1024x768/444363201.jpg?k=0be692cb5df7d3cef26f2028fae0e45265dceac24587d56d6e771596355e8987&o=',
                    imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
                }, {
                    id: '6',
                    imageSrc: 'https://q-xx.bstatic.com/xdata/images/hotel/max1024x768/419192458.jpg?k=5c67fe5346e6c7d870a33417a6752af05f962dc9fed9871c6fe3b10e388a9486&o=',
                    imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
                },
            ],
        },
    },
    {
        id: "143d8df3-49c7-4370-9e0e-0c5ef7a46f6b",
        name: " ",
        version: "v1",
        style: "CLASSIC",
        path: "index",
        data: {
            type: "Product",
            id: "k",
            title: "Our Rooms!",
            subTitle: "Luxury Rooms at Gayatri Lodge, Kasauli",
            description: "Hero Banner Paragraph is here",
            version: "v1",
            style: "CLASSIC",
            products: [
                {
                    id: 1,
                    name: 'Deluxe Room',
                    href: '#',
                    description: 'Spacious, elegant, and luxurious accommodation for ultimate comfort.',
                    imageSrc: "https://images.unsplash.com/photo-1611892440504-42a792e24d32?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
                    imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
                },
                {
                    id: 2,
                    name: 'Master Room',
                    href: '#',
                    description: 'Opulent, indulgent, and exclusive retreat for discerning guests.',
                    imageSrc: "https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
                    imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
                },
                {
                    id: 3,
                    name: 'Super Room',
                    href: '#',
                    description: ' Upgraded, luxurious, and lavish accommodation for a remarkable stay.',
                    imageSrc: "https://images.unsplash.com/photo-1618773928121-c32242e63f39?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
                    imageAlt: 'Person using a pen to cross a task off a productivity paper card.',
                },
                {
                    id: 4,
                    name: 'Normal Room',
                    href: '#',
                    description: 'Comfortable, affordable, and practical choice for a pleasant stay.',
                    imageSrc: "https://images.unsplash.com/photo-1591088398332-8a7791972843?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
                    imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
                },
                // More products...
            ],
        },
    },
    {
        id: "143d8df3-49c7-4370-9e0e-0c5ef7a46f6b",
        name: "Service",
        version: "v1",
        style: "CLASSIC",
        path: "index",
        data: {
            type: "Service",
            id: "k",
            title: "Our Services",
            subTitle: "",
            description: "",
            version: "v1",
            style: "CLASSIC",
            services: [
                {
                    id: 1,
                    title: 'Parking Space',
                    body: 'We dejoy working with discerning clients, people for whom qualuty, service, integrity & aesthetics.',
                    imageSrc: 'https://htmldemo.zcubethemes.com/riorelax/img/icon/sve-icon3.png',
                    imageAlt: 'parking icon',
                },
                {
                    id: 2,
                    title: 'Room Service',
                    body: 'We dejoy working with discerning clients, people for whom qualuty, service, integrity & aesthetics.',
                    imageSrc: 'https://htmldemo.zcubethemes.com/riorelax/img/icon/fe-icon01.png',
                    imageAlt: 'parking icon',
                },
                {
                    id: 3,
                    title: 'Fibre Internet',
                    body: 'We dejoy working with discerning clients, people for whom qualuty, service, integrity & aesthetics.',
                    imageSrc: 'https://htmldemo.zcubethemes.com/riorelax/img/icon/sve-icon2.png',
                    imageAlt: 'parking icon',
                },
                {
                    id: 4,
                    title: 'Special Offers',
                    body: 'We dejoy working with discerning clients, people for whom qualuty, service, integrity & aesthetics.',
                    imageSrc: 'https://htmldemo.zcubethemes.com/riorelax/img/icon/fe-icon08.png',
                    imageAlt: 'parking icon',
                },
                {
                    id: 5,
                    title: 'Best Accommodation',
                    body: 'We dejoy working with discerning clients, people for whom qualuty, service, integrity & aesthetics.',
                    imageSrc: 'https://htmldemo.zcubethemes.com/riorelax/img/icon/fe-icon05.png',
                    imageAlt: 'parking icon',
                },
                {
                    id: 6,
                    title: 'Breakfast',
                    body: 'We dejoy working with discerning clients, people for whom qualuty, service, integrity & aesthetics.',
                    imageSrc: 'https://htmldemo.zcubethemes.com/riorelax/img/icon/fe-icon05.png',
                    imageAlt: 'parking icon',
                },
            ],
        },
    },
    {
        id: "143d8df3-49c7-4370-9e0e-0c5ef7a46f6b",
        name: "Service",
        version: "v1",
        style: "CLASSIC",
        path: "index",
        data: {
            type: "Blog",
            id: "k",
            title: "Our Blog Posts!",
            subTitle: "Hero Banner Sub Title is here",
            description: "Hero Banner Paragraph is here",
            version: "v1",
            style: "CLASSIC",
            blogs: [
                {
                    id: "1",
                    title: "Tranquil Stays: Gayatri Lodge, Kasauli",
                    description: "Discover serenity and unmatched comfort at Gayatri Lodge, nestled in the heart of Kasauli.",
                    descriptionContext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonummy tincidunt ut lacreet dolore magna aliguam erat volutpat.",
                    imageSrc: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/462717126.jpg?k=0a09a8f39a458aa2160a678ffa42a0f9ac3f9936bd43496122b2a6bf3bd9ac58&o=&hp=1",
                    imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
                },
                {
                    id: "2",
                    title: "Luxury Unveiled: Gayatri Lodge Hotel",
                    description: "Experience unparalleled luxury at Gayatri Lodge Hotel, where opulence meets elegance. Indulge in lavish accommodations, impeccable services, and breathtaking views.",
                    descriptionContext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonummy tincidunt ut lacreet dolore magna aliguam erat volutpat.",
                    imageSrc: "https://images.unsplash.com/photo-1591088398332-8a7791972843?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
                    imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
                },
                {
                    id: "3",
                    title: "Heartfelt Welcome: Gayatri Lodge Homestay",
                    description: "At Gayatri Lodge, we extend heartfelt welcomes and make you feel like family. Discover the warmth of personalized hospitality that turns your stay into cherished memories.",
                    descriptionContext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonummy tincidunt ut lacreet dolore magna aliguam erat volutpat.",
                    imageSrc: "https://images.unsplash.com/photo-1591088398332-8a7791972843?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
                    imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
                },
                {
                    id: "4",
                    title: "Embrace Warmth: Personalized Hospitality at Gayatri Lodge",
                    description: "At Gayatri Lodge, we extend heartfelt welcomes and make you feel like family. Discover the warmth of personalized hospitality that turns your stay into cherished memories.",
                    descriptionContext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonummy tincidunt ut lacreet dolore magna aliguam erat volutpat.",
                    imageSrc: "https://images.unsplash.com/photo-1591088398332-8a7791972843?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
                    imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
                }, {
                    id: "5",
                    title: "Stunning Views: Gayatri Lodge's Vistas",
                    description: "Immerse yourself in breathtaking vistas that surround Gayatri Lodge, offering nature's beauty at its finest.",
                    descriptionContext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonummy tincidunt ut lacreet dolore magna aliguam erat volutpat.",
                    imageSrc: "https://images.unsplash.com/photo-1591088398332-8a7791972843?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
                    imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
                }, {
                    id: "6",
                    title: "A Culinary Journey: Flavors of Kasauli at Gayatri Lodge",
                    description: " Delight your taste buds with a gastronomic experience like no other at Gayatri Lodge. Savor local delicacies and international cuisines that tantalize the palate.",
                    descriptionContext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonummy tincidunt ut lacreet dolore magna aliguam erat volutpat.",
                    imageSrc: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/462717126.jpg?k=0a09a8f39a458aa2160a678ffa42a0f9ac3f9936bd43496122b2a6bf3bd9ac58&o=&hp=1",
                    imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
                },
            ],
        },
    },
    {
        id: "143d8df3-49c7-4370-9e0e-0c5ef7a46f6b",
        name: "Service",
        version: "v1",
        style: "CLASSIC",
        path: "index",
        data: {
            type: "Testimonial",
            id: "k",
            title: "Testimonials",
            subTitle: "Hero Banner Sub Title is here",
            description: "Hero Banner Paragraph is here",
            version: "v1",
            style: "CLASSIC",
            testimonials: [
                {
                    id: "1",
                    name: "John Doe",
                    designation: "Graphic Designer",
                    imageSrc: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(2).jpg",
                    imageAlt: "Graphic Designer",
                    testimonial:
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac magna sit amet risus tristique interdum, at vel velit in hac habitasse platea dictumst.",
                },
                {
                    id: "2",
                    name: "John Doe",
                    designation: "John Doe",
                    imageSrc: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).jpg",
                    imageAlt: "John Doe",
                    testimonial:
                        "Praesent placerat enim ut ex tincidunt vehicula. Fusce sit amet dui tellus. Morbi sagittis, quam nec venenatis lobortis, mi risus tempus nulla, sed porttitor est nibh at nulla.",
                },
                {
                    id: "3",
                    name: "John Doe",
                    designation: "John Doe",
                    imageSrc: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(9).jpg",
                    imageAlt: "John Doe",
                    testimonial:
                        "Ut posuere, ex a fringilla hendrerit, nulla metus gravida mi, in hendrerit quam odio sed est. Vestibulum nec finibus nisi. Aliquam et ipsum nulla.",
                }, {
                    id: "4",
                    name: "John Doe",
                    designation: "John Doe",
                    imageSrc: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(9).jpg",
                    imageAlt: "John Doe",
                    testimonial:
                        "Ut posuere, ex a fringilla hendrerit, nulla metus gravida mi, in hendrerit quam odio sed est. Vestibulum nec finibus nisi. Aliquam et ipsum nulla.",
                },
            ],
        },
    },
    {
        id: "143d8df3-49c7-4370-9e0e-0c5ef7a46f6b",
        name: "ContactUs",
        version: "v1",
        style: "CLASSIC",
        path: "index",
        data: {
            type: "ContactUs",
            id: "k",
            title: "Get in Touch",
            subTitle: "Hero Banner Sub Title is here",
            description: "Hero Banner Paragraph is here",
            version: "v1",
            style: "CLASSIC",
            contactUs: {
                address: "Village Kimughat P.O. Garkhal, Tehsil, Kasauli, Himachal Pradesh 173201",
                email: "gayatrilodgekasauli@gmail.com",
                phone: "9857315551",
                formFields: [
                    { name: 'name', label: 'Name', type: 'text', required: true },
                    { name: 'mobile', label: 'Mobile', type: 'tel', required: true },
                    { name: 'email', label: 'Email', type: 'text', required: true },
                    { name: 'message', label: 'Masssege', type: 'text', required: false },
                ]
            },
        },
    },
    {
        id: "143d8df3-49c7-4370-9e0e-0c5ef7a46f6b",
        name: "Faq",
        version: "v1",
        style: "CLASSIC",
        path: "index",
        data: {
            type: "FAQ",
            id: "k",
            title: "FAQ",
            subTitle: "Hero Banner Sub Title is here",
            description: "Hero Banner Paragraph is here",
            version: "v1",
            style: "CLASSIC",
            faqs: [
                {
                    id: 1,
                    question: 'Q.What are the check-in and check-out timings at Gayatri Lodge?',
                    answer: 'Ans:The standard check-in time at Gayatri Lodge is 2:00 PM, and the check-out time is 12:00 PM (noon). However, early check-in or late check-out requests can be accommodated based on availability and may be subject to additional charges.',
                },
                {
                    id: 2,
                    question: 'Q.What amenities are provided at Gayatri Lodge?',
                    answer: 'Ans:Gayatri Lodge offers a range of amenities to make your stay comfortable. Some of the amenities include clean and well-furnished rooms, air conditioning, television, attached bathroom with hot water facility, room service, Wi-Fi access, and complimentary toiletries.',
                },
                {
                    id: 3,
                    question: 'Q.Is there parking available at Gayatri Lodge?',
                    answer: 'Ans:Yes, Gayatri Lodge provides parking facilities for guests. You can safely park your vehicle during your stay at the lodge. However, parking availability may be limited, so its advisable to inform the lodge in advance if you require parking.',
                },
                {
                    id: 4,
                    question: 'Q.Are meals included in the room rates at Gayatri Lodge?',
                    answer: 'Ans:"Yes, Gayatri Lodge provides meals as part of the room rates. Guests can enjoy delicious meals at the in-house restaurant or avail themselves of the dining options available in close proximity to the lodge. The meals offered cater to various preferences and cuisines, ensuring a satisfying dining experience for guests."',
                },
                {
                    id: 5,
                    question: 'Q.Does Gayatri Lodge have a cancellation policy?',
                    answer: 'Ans:Yes, Gayatri Lodge has a cancellation policy in place. The specific details may vary depending on the booking channel and the type of reservation made. Its advisable to review the cancellation policy at the time of booking or contact the lodge directly for more information.',
                },
                // Add more FAQs as needed
            ],
        },
    },
    {
        id: "143d8df3-49c7-4370-9e0e-0c5ef7a46f6b",
        name: "Footer",
        version: "v1",
        style: "CLASSIC",
        path: "index",
        data: {
            type: "Footer",
            id: "k",
            title: "Hero Banner!",
            subTitle: "Hero Banner Sub Title is here",
            description: "Hero Banner Paragraph is here",
            version: "v1",
            style: "CLASSIC",
        },
    },
];

export const gayatrilodgeGuestRegistration: Section[] = [
    {
        id: "69e7ed7e-889d-41c0-a1d9-62bd150401e2",
        name: "Header",
        version: "v2",
        style: "CLASSIC",
        path: "index",
        data: {
            type: "Header",
            id: "001",
            title: "Gayatri Lodge",
            subTitle: "Hero Banner Sub Title is",
            description: "Hero Banner Paragraph is here",
            version: "v2",
            style: "CLASSIC",
            menu: [
                {
                    id: "menu-1",
                    label: "Home",
                    url: "/home",
                }, {
                    id: "menu-2",
                    label: "About Us",
                    url: "/about-us",
                }, {
                    id: "menu-4",
                    label: "Gallery",
                    url: "/gallery",
                }, {
                    id: "menu-3",
                    label: "Contact us",
                    url: "/contact-us",
                },
            ],
        },
    },
    {
        id: "143d8df3-49c7-4370-9e0e-0c5ef7a46f6b",
        name: "ContactUs",
        version: "v1",
        style: "CLASSIC",
        path: "index",
        data: {
            type: "ContactUs",
            id: "k",
            title: "Get in Touch",
            subTitle: "Hero Banner Sub Title is here",
            description: "Hero Banner Paragraph is here",
            version: "v1",
            style: "CLASSIC",
            contactUs: {
                address: "Village Kimughat P.O. Garkhal, Tehsil, Kasauli, Himachal Pradesh 173201",
                email: "gayatrilodgekasauli@gmail.com",
                phone: "9857315551",
                formFields: [
                    { name: 'name', label: 'Name', type: 'text', required: true },
                    // { name: 'mobile', label: 'Mobile', type: 'tel', required: true },
                    // { name: 'email', label: 'Email', type: 'text', required: true },
                    // { name: 'message', label: 'Masssege', type: 'text', required: false },
                ]
            },
        },
    },
    {
        id: "143d8df3-49c7-4370-9e0e-0c5ef7a46f6b",
        name: "Footer",
        version: "v1",
        style: "CLASSIC",
        path: "index",
        data: {
            type: "Footer",
            id: "k",
            title: "Hero Banner!",
            subTitle: "Hero Banner Sub Title is here",
            description: "Hero Banner Paragraph is here",
            version: "v1",
            style: "CLASSIC",
        },
    },
];

export const gayatrilodgeSeo: any = {
    title: "Gayatri Lodge Hotel - Your Tranquil Haven in Kasauli | Homestay",
    description: "Experience a tranquil and unforgettable stay at Gayatri Lodge, a charming homestay nestled in the heart of Kasauli. Enjoy luxurious rooms, stunning views, and personalized hospitality.",
    keywords: "Gayatri Lodge, Gayatri Lodge Hotel, Hotel, Kasauli, homestay, luxurious rooms, stunning views, personalized hospitality, tranquil stay",
    author: "Your Name",
    imageUrl: "https://example.com/og-image.jpg",
    siteUrl: "https://www.gayatrilodgekasauli.com",
};

export const gaytriLodgePlugin: PluginConfig[] = [
    {
        name: "LocationButton",
        isActive: true,
        location: {
            locationName: "Gayatri Lodge Hotel in Kasauli",
            latitude: 30.89901139661188,
            longitude: 76.97811964534783,

        },
    }
]