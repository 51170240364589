import React from "react";
import { SiFsecure } from "react-icons/si";
import { GiPriceTag } from "react-icons/gi";
interface ThirdAboutUsProps {
  data: any;
}
const details = [
  {
    title: "Safety Always First",
    desc: "We prioritizes your safety for a secure and thrilling adventure.",
    icons: <SiFsecure color="#fff" size={20} />,
  },
  {
    title: "Low Prices & Friendly",
    desc: "Affordable adventures,unforgettable experiences in the Himalayas",
    icons: <GiPriceTag color="#fff" size={22} />,
  },
];
const icons = [
  <SiFsecure color="#fff" size={20} />,
  <GiPriceTag color="#fff" size={20} />,
];
const ThirdAboutUs: React.FC<ThirdAboutUsProps> = ({ data }) => {
  return (
    <section className="flex items-center py-10 bg-stone-100 xl:h-screen font-poppins dark:bg-gray-800 ">
      <div className="justify-center flex-1 max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
        <div className="flex flex-wrap ">
          <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0">
            <div className="relative">
              <img
                src="https://images.unsplash.com/photo-1545652985-5edd365b12eb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
                alt=""
                className="relative z-40 object-cover w-full h-96 lg:rounded-tr-[80px] lg:rounded-bl-[80px] rounded"
              />
              <div className="absolute z-10 hidden w-full h-full bg-teal-400 rounded-bl-[80px] rounded -bottom-6 right-6 lg:block"></div>
              <div className="absolute z-50 text-teal-400 transform -translate-y-1/2 cursor-pointer top-1/2 left-[46%] hover:text-teal-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="w-14 h-14 bi bi-play-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"></path>
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0 ">
            <div className="relative">
              <h1 className="absolute -top-20   left-0 text-[20px] lg:text-[100px] text-gray-900 font-bold  dark:text-gray-200 opacity-5 md:block hidden">
                About Us
              </h1>
              <h1 className="pl-2 text-3xl font-bold border-l-8 border-teal-400 md:text-5xl dark:text-white">
                ADVENTURES & <span className="text-teal-500">EXPEDITION.</span>
              </h1>
            </div>
            <p className="mt-6 mb-10 text-lg leading-7 text-black dark:text-gray-400">
              Discover thrilling journeys with us, where every path leads to
              extraordinary adventures.
            </p>
            <div>
              {details.map((item, index) => (
                <div key={index} className="flex items-center mr-5 mb-6">
                  <div className="p-6 mr-2 bg-teal-400 rounded-full">
                    {item.icons}
                  </div>
                  <div className="ml-7">
                    <h1
                      className={`text-lg font-bold ${
                        index === 0 ? "text-orange-500" : "text-teal-400"
                      } dark:text-white`}
                    >
                      {item.title}
                    </h1>
                    <p className=" text-[17px] text-black dark:text-gray-400">
                      {item.desc}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThirdAboutUs;
