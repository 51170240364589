export class Config {
    // static SPA_SERVICE_BASE_URL = "https://cb56-2409-40d7-6-8614-84f9-26a4-f614-9c5f.ngrok-free.app/";
    static CHATBOT_SERVICE_BASE_URL = "https://chatbot.pahariyatri.com/";
    static SPA_SERVICE_BASE_URL = " http://localhost:8090/";
    static BLOG_SERVICE_BASE_URL = "http://localhost:8091/";
    static IS_LOGGED_IN = "isLoggedIn";
    static ACCESS_TOKEN = "accessToken";
    static USER_LOCATION = "userLocation";
    static REFRESH_TOKEN = "refreshToken";
    static SESSION_USER = "sessionUser";
    static CLIENT_ID = "clientId";
}
