import React, { useContext, useEffect } from "react";
import { useDynamicTextColor } from "../../../themes/DynamicTextColor";
import { ThemeContext } from "../../../themes/ThemeProvider";

export default function ThirdHeroBanner(props: { data: any }) {
  const { theme } = useContext(ThemeContext);
  const textColor = useDynamicTextColor(theme.colors.primary || "");
  const bannerData = props.data.bannerData?.[0];
  useEffect(() => {
    // No need to set bannerData again, it's already initialized with props.data
  }, [props.data]);
  return (
    <>
      <section className={`bg-${theme.colors.primary} font-bold`}>
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:pb-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <span className="inline-flex text-lg items-center justify-center px-5 py-2 mb-2  font-medium text-center text-white rounded-lg bg-teal-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
              {bannerData.title}
            </span>
            <h1 className={`max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-5xl dark:${textColor}`}>
              {bannerData.subTitle}
              {/* <span className="text-teal-500 text-6xl">FOR YOU.</span> */}
            </h1>
            <p className={`max-w-2xl mb-6 font-normal text-black lg:mb-8 md:text-lg lg:text-xl dark:${textColor}`}>
              {bannerData.description}
            </p>
            <a
              href={bannerData.buttonLink ?? "#portfolio"}
              className="inline-flex items-center justify-center px-10 py-3  text-base font-medium text-center text-white rounded-lg bg-orange-400 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
            >
              {bannerData.buttonLevel ?? "LET'S GET STARTED"}
              <svg
                className="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
          <div className="hidden relative lg:mt-0 lg:col-span-5 lg:flex">
            <img
              src={bannerData.image.imageSrc}
              alt="mockup"
              className="z-10 rounded-full bg-orange-400 p-5"
            />
            <div className="absolute z-0 right-30 bg-teal-500 w-[370px] top-24 left-[270px] h-[488px] rounded-tr-3xl inset-0 flex items-center justify-center"></div>
          </div>
        </div>
      </section>
    </>
  );
};

