import { Section } from "../modal/Section";

export const blogTheme: any = {
    name: "classic"
}

export const blogpahariyatri: Section[] = [
    {
        id: "69e7ed7e-889d-41c0-a1d9-62bd150401e2",
        name: "Header",
        version: "v2", // Add version property
        style: "CLASSIC", // Add style property
        path: "index",
        data: {
            type: "Header",
            id: "001",
            title: "Blog",
            subTitle: "Hero Banner Sub Title is",
            description: "Hero Banner Paragraph is here",
            version: "v1",
            style: "CLASSIC",
        },
    },
    {
        id: "69e7ed7e-889d-41c0-a1d9-62bd150401e2",
        name: "Herobanner",
        version: "v1", // Add version property
        style: "CLASSIC", // Add style property
        path: "index",
        data: {
            type: "HeroBanner",
            id: "002",
            title: "Pahari Yatri",
            subTitle: "Pahari Yatri - A Guide to Traveling on a Budget",
            description: "Hero Banner Paragraph is here",
            version: "v2",
            style: "CLASSIC",
            bannerData: [
                {
                    title: "Pahari Yatri",
                    subTitle: "Pahari Yatri - A Guide to Traveling on a Budget",
                    description: "Archdy is your premier destination for innovative architecture, interior design, and landscape solutions. Explore our portfolio and contact us today for expert consultation.",
                    image:
                    {
                        id: '1',
                        imageSrc: 'https://images.unsplash.com/photo-1475094101105-3f300a76f60c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1173&q=80',
                        imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
                    },
                    videoSrc: "string",
                    modalProps: {
                        isOpen: true,
                        // onClose: () => void,
                        title: 'Title is here ',
                    }
                },
            ],
        },
    },
    {
        id: "143d8df3-49c7-4370-9e0e-0c5ef7a46f6b",
        name: "Blog",
        version: "v1",
        style: "CLASSIC",
        path: "index",
        data: {
            type: "Blog",
            id: "k",
            title: "Hero Banner!",
            subTitle: "Hero Banner Sub Title is here",
            description: "Hero Banner Paragraph is here",
            version: "v1",
            style: "CLASSIC",
            blogs: [
                {
                    id: "1",
                    title: "Hampta Pass Trek: A Journey from Green to Snow",
                    description: 'Embark on a captivating adventure from the lush green valleys of Kullu to the snow-clad wonderland of Spiti. Discover the magic of Hampta Pass and its breathtaking vistas in this unforgettable blog.',
                    descriptionContext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonummy tincidunt ut lacreet dolore magna aliguam erat volutpat.",
                    imageSrc: "https://images.unsplash.com/photo-1656833111546-07d98abab448?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
                    imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
                },
                {
                    id: "2",
                    title: "Roopkund Trek: Unraveling the Mystery of Skeleton Lake",
                    description: 'Join us as we venture into the enigmatic world of Roopkund. Delve into the legend, history, and natural beauty surrounding this mystical lake in our thrilling blog.',
                    descriptionContext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonummy tincidunt ut lacreet dolore magna aliguam erat volutpat.",
                    imageSrc: "https://images.unsplash.com/photo-1545652985-5edd365b12eb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
                    imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
                },
                {
                    id: "3",
                    title: "Valley of Flowers Trek: A Kaleidoscope of Colors",
                    description: 'Immerse yourself in the kaleidoscope of colors as we explore the stunning Valley of Flowers. Discover the unique flora and fauna that make this trek a once-in-a-lifetime experience.',
                    descriptionContext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonummy tincidunt ut lacreet dolore magna aliguam erat volutpat.",
                    imageSrc: "https://images.unsplash.com/photo-1564432177374-603c4ba4ee69?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1109&q=80",
                    imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
                },
                {
                    id: "4",
                    title: "Har Ki Dun Trek: A Divine Sojourn in the Valley of Gods",
                    description: "Follow our footsteps into the mystical Har Ki Dun Valley, where nature's beauty meets divine serenity. Read about our encounters with local culture and the enchanting landscapes in this captivating blog.",
                    descriptionContext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonummy tincidunt ut lacreet dolore magna aliguam erat volutpat.",
                    imageSrc: "https://images.unsplash.com/photo-1520982184827-6950930eaf2b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
                    imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
                }, {
                    id: "5",
                    title: "Kedarkantha Trek: A Winter Wonderland in the Himalayas",
                    description: "Embark on a winter wonderland adventure as we trek to the summit of Kedarkantha. Join us as we unravel the charm of this snow-laden trek and its mesmerizing panoramas.",
                    descriptionContext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonummy tincidunt ut lacreet dolore magna aliguam erat volutpat.",
                    imageSrc: "https://images.unsplash.com/photo-1612438214708-f428a707dd4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
                    imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
                }, {
                    id: "6",
                    title: "Deoriatal Chandrashila Trek: A Spiritual Journey to the Heights",
                    description: "Walk with us on a spiritual journey to the heights of Chandrashila. Discover the beauty of Deoriatal Lake and the spiritual essence of this trek in our insightful blog.",
                    descriptionContext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonummy tincidunt ut lacreet dolore magna aliguam erat volutpat.",
                    imageSrc: "https://images.unsplash.com/photo-1465919292275-c60ba49da6ae?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1211&q=80",
                    imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
                }, {
                    id: "7",
                    title: "Bhrigu Lake Trek: Amidst the Shimmering Waters and High Peaks",
                    description: "Discover the enchanting Bhrigu Lake, nestled amidst the shimmering waters and towering peaks. Join us on this offbeat trek and witness nature's splendor.",
                    descriptionContext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonummy tincidunt ut lacreet dolore magna aliguam erat volutpat.",
                    imageSrc: "https://images.unsplash.com/photo-1503265192943-9d7eea6fc77a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
                    imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
                }, {
                    id: "8",
                    title: "Nag Tibba Trek: Unraveling the Hidden Gem of Garhwal",
                    description: " Unravel the hidden gem of Garhwal as we trek to the Nag Tibba summit. Delight in panoramic views, dense forests, and a glimpse of the snow-capped Himalayas.",
                    descriptionContext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonummy tincidunt ut lacreet dolore magna aliguam erat volutpat.",
                    imageSrc: "https://images.unsplash.com/photo-1621234672278-1c61ec66120a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
                    imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
                }, {
                    id: "9",
                    title: "Beas Kund Trek: Tracing the Source of River Beas",
                    description: "Trace the source of the mighty Beas River on the Beas Kund Trek. Walk through alpine meadows, glaciers, and pristine landscapes in this awe-inspiring journey.",
                    descriptionContext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonummy tincidunt ut lacreet dolore magna aliguam erat volutpat.",
                    imageSrc: "https://images.unsplash.com/photo-1642832656516-f26c36090149?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
                    imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
                },
            ],
        },
    },
    {
        id: "143d8df3-49c7-4370-9e0e-0c5ef7a46f6b",
        name: "Footer",
        version: "v1",
        style: "CLASSIC",
        path: "index",
        data: {
            type: "Footer",
            id: "k",
            title: "Hero Banner!",
            subTitle: "Hero Banner Sub Title is here",
            description: "Hero Banner Paragraph is here",
            version: "v1",
            style: "CLASSIC",
        },
    },
];

export const blogpahariyatriseo: any = {
    title: "Exploring the Uncharted: Trekking and Mountaineering with Pahari Yatri",
    description: "Embark on a journey of a lifetime with Pahari Yatri as we delve into the world of trekking and mountaineering, offering thrilling experiences in breathtaking landscapes.",
    keywords: "trekking, mountaineering, adventure travel, Pahari Yatri, outdoor exploration, nature adventures",
    tags: "trekking, mountaineering, adventure travel, Pahari Yatri, outdoor exploration, nature adventures",
    author: "Pankaj Kumar",
    imageUrl: "https://example.com/blog-og-image.jpg",
    siteUrl: "https://www.blog.pahariyatri.com",
};